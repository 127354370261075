<template>
    <Setup-Step
        @next="f => f()"
        fluid
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        step="order-request-courier"
        title="Request Courier">
        <template v-slot:body>
            <BT-Order-Cycle-Header showTrimmings highlightRequestCourier title="Order Pipeline: Request Courier" />

            <v-divider class="my-4" />

            <Setup-Block>
                <BT-Entity
                    ignoreID
                    inline
                    navigation="courier-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-subheader>Requesting A Courier</v-subheader>

                        <p>
                            Once the consignment is ready, you can request a courier (either yourself or a third party) to pickup and/or deliver it.  
                            BlitzIt Web can be configured to do this automatically or manually via user input.  
                            To be automatic, you need to define what geographical areas your couriers will cover.
                        </p>

                        <v-row>
                            <v-col cols="8">
                                <BT-Square-Check
                                    @change="save"
                                    falseClass="success--text"
                                    falseIcon="mdi-account-circle-outline"
                                    falseValue="Agreements"
                                    isEditing
                                    :label="item.courierSelectionGuideAsSupplier == 'Settings' ? 'Apply To Every Customer' : 'Different For Each Customer'"
                                    outlined
                                    trueIcon="mdi-earth"
                                    trueValue="Settings"
                                    v-model="item.courierSelectionGuideAsSupplier" />
                            </v-col>
                            <v-col cols="4" class="align-self-center">
                                <v-slide-x-transition hide-on-leave>
                                    <BT-Square-Check
                                        v-if="item.courierSelectionGuideAsSupplier == 'Settings'"
                                        @change="save"
                                        falseClass="success--text"
                                        falseIcon="mdi-boom-gate"
                                        falseLabel="Manual"
                                        falseValue="Manual"
                                        isEditing
                                        :label="item.courierSelectionTriggerAsSupplier"
                                        outlined
                                        trueIcon="mdi-boom-gate-up"
                                        trueLabel="Auto"
                                        trueValue="Auto"
                                        v-model="item.courierSelectionTriggerAsSupplier" />
                                    <p v-else>
                                        (Define this when you add new customers)
                                    </p>
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>

                        <v-divider class="my-4" />

                        <v-subheader>Pickup Only</v-subheader>

                        <p>
                            You can opt to have all of your customers pickup rather than organize a courier to pickup and deliver.  
                            This can only be defined for each individual customer.
                        </p>

                        <v-row>
                            <v-col cols="8">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-account-circle-outline"
                                    falseValue="Agreements"
                                    label="Different For Each Customer"
                                    outlined
                                    trueIcon="mdi-earth"
                                    trueValue="Settings"
                                    v-model="mData" />
                            </v-col>
                            <v-col cols="4" class="align-self-center">
                                <p>(Define this when you add new customers)</p>
                            </v-col>
                        </v-row>

                        <v-divider class="my-4" />
                    </template>
                </BT-Entity>

            </Setup-Block>
            
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Order-Ready-For-Courier-Setup',
    components: {
        BTOrderCycleHeader: () => import('~components/BT-Order-Cycle-Header.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0,
            mData: 'Agreements'
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>